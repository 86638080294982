/* prefixed by https://autoprefixer.github.io (PostCSS: v7.0.23, autoprefixer: v9.7.3) */

/* Cormorant font */
@font-face {
    font-family: 'Cormorant SC';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/Cormorant_SC/CormorantSC-Regular.ttf);
}

/* Tajawal font */
@font-face {
    font-family: 'Tajawal';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(../fonts/Tajawal/Tajawal-Regular.ttf);
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    scroll-behavior: smooth;
    font-family: 'Tajawal', sans-serif;
}

body {
    margin: 0;
}

.bold {
    font-weight: bold;
}

a[href]:hover {
    text-decoration: none;
}

.container {
    width: 100%;

    padding: 0 30px;
    margin: 0 auto;
}

.flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 850px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.illustration img,
#map {
    border-radius: 0.4rem;
}

/* ========== Navbar ========== */

nav {
    position: fixed;
    top: 0px;
    width: 100vw;
    padding: 0 0 0.4rem 0;

    z-index: 2;

    background: white;
    border-bottom: 1px solid #a63f03;

    -webkit-transition: background 0.25s ease-in-out, border 0.25s ease-in-out;

    -o-transition: background 0.25s ease-in-out, border 0.25s ease-in-out;

    transition: background 0.25s ease-in-out, border 0.25s ease-in-out;
}

@media (min-width: 1200px) {
    nav {
        padding: 0;
    }
    nav.not_scrolled {
        background-color: rgb(0, 0, 0, 0.35);
        border: none;
    }

    nav.not_scrolled li a,
    nav.not_scrolled .nav_title a {
        color: white;
    }

    nav.not_scrolled li a:after {
        background: rgba(255, 255, 255, 0.8);
    }
}

nav .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media (min-width: 1200px) {
    nav button {
        display: none;
    }
}

nav ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 0;
    margin: 0;
}

@media (max-width: 1200px) {
    nav:not(.expanded) {
        padding: 0;
    }

    nav:not(.expanded) ul {
        display: none;
    }
}

@media (min-width: 1200px) {
    nav ul {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

nav .nav_title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    -ms-flex-preferred-size: 100%;

    flex-basis: 100%;
    padding: 0.6rem 0;
}

@media (min-width: 1200px) {
    nav .nav_title {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
        padding: 0.2rem;
    }

    nav.not_scrolled .nav_title {
        text-shadow: rgba(0, 0, 0, 1) 0px 2px 5px;
    }
}

nav .logo {
    height: 2.6rem;
    margin-right: 0.5rem;
}

nav button {
    outline: none;
    background: transparent;
    border: none;

    color: #400101;
    font-size: 1.2rem;

    padding: 0.2rem 0.5rem;
    margin-left: 0.5rem;
}

nav li {
    list-style-type: none;
}

nav li a,
nav .nav_title a {
    font-family: 'Open Sans', 'Helvetica Neue', Arial, sans-serif;
    -webkit-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

nav li a:hover {
    color: #400101;
}

nav.not_scrolled li a:hover {
    color: white;
}

nav .nav_title a {
    /* text-transform: uppercase; */
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    text-decoration: none;

    margin: 0 1rem;

    color: #400101;
}

nav li a {
    color: #400101;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.1rem;
    text-align: center;
    text-decoration: none;
    margin: 0;

    display: block;
    position: relative;
    padding: 1rem 0;

    border-top: 1px solid #a63f03;
}

@media (min-width: 1200px) {
    nav li a {
        margin: 0 1rem;
        padding: 0.4rem 0;
        border: none;

        font-size: 0.95rem;
    }

    nav li a:after {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        bottom: 2px;
        margin: auto;
        height: 1px;
        width: 0%;
        background: #400101;
        -webkit-transition: width 0.25s ease-in-out;
        -o-transition: width 0.25s ease-in-out;
        transition: width 0.25s ease-in-out;
        box-shadow: rgba(0, 0, 0, 0.7) 0px 2px 5px;
    }

    nav li a:hover:after {
        width: 60%;
    }

    nav.not_scrolled li a {
        text-shadow: rgba(0, 0, 0, 0.7) 0px 2px 5px;
    }
}

.subtitle {
    font-size: 2.8rem;
    font-weight: 400;
    text-align: center;
    text-shadow: rgba(64, 1, 1, 0.5) 01px 1px 3px;

    margin: 0 auto;
}

.subtitle.dark {
    color: #400101;
}

.subtitle.light {
    color: #ddefeb;
}

header {
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-attachment: scroll;
    background-position: center;

    padding-top: 3.8rem;

    height: 100vh;
    min-height: 800px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    position: relative;
}

header::before {
    background-image: url(../img/gel_bg.jpg);
    background-position: 50% 50%;
    background-size: cover;
    filter: brightness(0.9);
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

@media (min-width: 1200px) {
    header {
        padding-top: 3rem;
    }
}

header .header-content {
    position: relative;
    background: rgba(38, 38, 38, 0.15);
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 20px;
}

.header-content .logo {
    height: 12rem;
    border: 5px solid #f0f0f0;
    border-radius: 100%;
    box-shadow: 0px 3px 10px #000;
}

header .bandeau-promo {
    font-size: 1.6rem;
    margin: 2rem 0 0 0;
    color: white;
    background: rgba(255, 0, 0, 0.83);
    padding: 0.2rem 1rem !important;
    border-radius: 10px;
    transition: all 0.2s ease;
    text-align: center;
    text-decoration: none;
    vertical-align: text-bottom;
}

header .bandeau-promo:hover {
    color: white;
    transform: scale(1.1);
}

header .title {
    font-family: 'Cormorant SC', serif;
    color: #f0f0f0;
    font-size: 3.2em;
    font-weight: bold;
    text-align: center;
    margin: 1rem;
    text-shadow: rgba(0, 0, 0, 1) 2px 2px 3px;
}

@media (min-width: 768px) {
    header .title {
        font-size: 5rem;
    }
}

header .message {
    color: #f0f0f0;
    font-size: 1.2em;
    font-weight: bold;
    text-align: center;
    margin: 1rem;
    text-shadow: 1px 1px 2px #000;
}

@media (min-width: 768px) {
    header .message {
        font-size: 1.2rem;
    }
}

header h1 {
    font-family: 'Tajawal', sans-serif;
    font-weight: bold;
    color: #f0f0f0;
    font-size: 2em;
    text-align: center;
    text-shadow: 0px 2px 10px #000;
}

header .news {
    font-size: 1.2rem;
    text-decoration: none;
    color: #f0f0f0;
    text-shadow: 1px 1px 3px #000000;
    font-weight: bold;
    text-align: center;

    background: #00000059;
    padding: 0.8rem;
    border: none;
    border-radius: 8px;
    text-shadow: 0px 2px 10px #000;
    margin-top: 0;
}

header a.news:hover {
    cursor: pointer;
    transform: scale(1.02);
    transition: all 0.1s ease-in-out;
}

@media (min-width: 768px) {
    header h1 {
        font-size: 2.5rem;
    }
    header .news {
        font-size: 1.4rem;
    }
}

.scroll-link {
    display: none;
    position: absolute;
    bottom: 2rem;
}

@media (min-width: 992px) {
    .scroll-link {
        display: block;
    }
}

.scroll-icon {
    width: 1.6rem;
    filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.7));
}

#about,
#contact,
#autourMassages,
#protocole {
    background: #f0f0f0;
    padding: 2rem 0 4rem 0;
}

#about a {
    text-decoration: none;
}

.alternate-content {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;

    margin: 2rem auto;
}

.alternate-content .illustration {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 0 1rem;
}

.alternate-content .text {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;

    padding: 0;
}

.alternate-content .illustration img {
    /*height: 300px;*/
    width: 100%;
}

.alternate-content a {
    color: #400101;
}

#massages #cadre-nouveaute {
    border: 3px solid #c91313;
    background: #f0f0f0;
    border-radius: 14px;
    max-width: 800px;
    padding: 1rem 0;
    margin: 1rem auto;

    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

#massages #cadre-nouveaute .left {
    flex-basis: 100%;
    padding: 0 1rem;
}

#massages #cadre-nouveaute .right {
    flex-basis: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
}

@media (min-width: 992px) {
    #massages #cadre-nouveaute {
        flex-wrap: nowrap;
    }

    #massages #cadre-nouveaute .left {
        -ms-flex-preferred-size: 75%;
        flex-basis: 75%;
    }

    #massages #cadre-nouveaute .right {
        -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
    }
}

#massages #cadre-nouveaute .right img {
    width: 100%;
    max-width: 400px;
    margin: auto;
}

#massages #cadre-nouveaute h4 {
    color: #c91313 !important;
    margin: 0 0 1rem 0;
    font-weight: 600;
    text-align: center;
    font-size: 1.6rem;
}

#massages #cadre-nouveaute p {
    color: #830000 !important;
    font-size: 1.2rem;
    margin: 0.1rem 0;
    font-weight: 600;
}

#massages .alternate-content .illustration img {
    border-radius: 100%;
}

@media (min-width: 992px) {
    .alternate-content .md-right {
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
    }

    .alternate-content .md-left {
        -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
        order: 1;
    }
}

@media (min-width: 992px) {
    .alternate-content .illustration {
        -ms-flex-preferred-size: 40%;
        flex-basis: 40%;
    }

    .alternate-content .text {
        -ms-flex-preferred-size: 60%;
        flex-basis: 60%;
    }
}

@media (min-width: 768px) {
    .alternate-content .illustration {
        padding: 0 4rem;
    }
}

.container p {
    margin: 1em auto;
    font-size: 1.4rem;
    color: #400101;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;

    text-align: center;
}

@media (min-width: 992px) {
    .container p {
        font-size: 1.3rem;
    }
}

p.fl::first-letter {
    font-size: 125%;
    color: #400101;
}

p.no-margin {
    margin: 0 auto;
}

p.no-margin-bottom {
    margin-bottom: 0;
}

#massages,
#information,
#avis {
    background: #d9843b;
    padding: 3rem 0 4rem 0;
}

#protocole h2 {
    margin-bottom: 2rem;
}

#protocole .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#protocole .container p {
    margin: 0.4rem auto;
}

#protocole ul {
    font-size: 1.3rem;
    color: #400101;
    margin-top: 1rem;
}

#autourMassages a {
    text-decoration: underline;
    color: #400101;
}

@media (min-width: 990px) {
    #protocole ul {
        width: 70%;
    }
}

.massages-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    border-width: 2px 0;

    padding: 2rem;
    min-height: 430px;
}

.massages-subtitle,
.autourMassages-subtitle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.massages-subtitle h2,
.autourMassages-subtitle h2 {
    margin: auto 1.5rem;
}

@media (min-width: 992px) {
    .massages-subtitle h2 {
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.equipementPhotos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 3rem auto;
}

.equipementPhotos img {
    width: 60%;
    margin: 1rem 2rem;
    border-radius: 100%;
}

@media (min-width: 992px) {
    .equipementPhotos {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
    }

    .equipementPhotos img {
        width: 25%;
    }
}

#massages svg path,
#massages svg line,
#massages svg circle {
    stroke: #ddefeb;
}

.massages-left {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;

    margin-bottom: 2rem;
}

@media (min-width: 768px) {
    .massages-left {
        padding: 0 3rem;
    }
}

.massages-right {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
}

.massages-right .illustration-container {
    background: rgba(221, 239, 235, 0.5);
    border-radius: 8px;
    border: 2px solid #591902;
    position: relative;
}

.massages-right .massage-illustration {
    width: 100%;
    border-radius: 7px;
    vertical-align: bottom;
}

@media (min-width: 992px) {
    .massages-right {
        -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    }

    .massages-left {
        -ms-flex-preferred-size: 70%;
        flex-basis: 70%;
        margin: 0;
    }
}

.massage-title {
    color: #ddefeb;
    font-weight: 400;
    margin: 0;
    font-size: 2.2rem;
    text-align: center;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;

    -webkit-box-ordinal-group: 2;

    -ms-flex-order: 1;

    order: 1;
}

@media (min-width: 992px) {
    .massage-title {
        font-size: 2.2rem;
        -ms-flex-preferred-size: auto;
        flex-basis: auto;
    }
}

.massages-changer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (min-width: 992px) {
    .massages-changer {
        margin: 0;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        height: 5rem;
    }
}

.massages-changer i {
    margin: 1rem 1.6rem;
    color: #ddefeb;
    font-size: 2.2rem;

    cursor: pointer;
}

.massages-changer i.fa-angle-left {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
}

.massages-changer i.fa-angle-right {
    -webkit-box-ordinal-group: 5;
    -ms-flex-order: 4;
    order: 4;
}

.massages-changer .separator {
    -webkit-box-ordinal-group: 4;
    -ms-flex-order: 3;
    order: 3;
}

@media (min-width: 992px) {
    .massages-changer i {
        margin: 0 1.6rem;
        -webkit-box-ordinal-group: initial;
        -ms-flex-order: initial;
        order: initial;
    }

    .massages-changer * {
        -webkit-box-ordinal-group: initial !important;
        -ms-flex-order: initial !important;
        order: initial !important;
    }

    .massages-changer .separator {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.massage-card {
    width: 100%;
    padding-top: 1.4rem;
    -webkit-box-flex: 10;
    -ms-flex-positive: 10;
    flex-grow: 10;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.massage-card div > h4.massage-subtitle {
    color: #ddefeb;
    text-align: center;
    font-size: 1.4rem;
    margin: 2rem 0 2rem 0;
}

@media (min-width: 1200px) {
    .massage-card div > h4.massage-subtitle {
        margin: -1rem 0 2rem 0;
    }
}

.massages-content .separator hr {
    margin: 0.5rem auto;
    width: 60px;
    border: 1px solid #ddefeb;
}

@media (min-width: 992px) {
    .massages-content hr.separator {
        margin: 2rem auto;
    }
}

.massages-content .massage-description {
    text-align: center;
    margin: 0;
    font-size: 1.4rem;
}

.massages-content button {
    background: none;
    border: none;
    cursor: pointer;

    font-size: 1.4rem;
    color: #400101;
    text-decoration: underline;
}

.massages-content button:focus {
    outline: none;
}

.massages-left .progress-container {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media (min-width: 992px) {
    .massages-left .progress-container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        width: 80%;
    }
}

.massages-left hr.progress {
    margin: 1rem auto 0 auto;
    width: 0%;
    height: 1px;
    border: 1px solid #ddefeb;
}

.massages-left .fa-pause-circle,
.massages-left .fa-play-circle {
    margin-top: 1.4rem;
    text-align: center;
    font-size: 2rem;
    color: #ddefeb;
    cursor: pointer;
}

#massages h3 {
    text-align: center;
    color: #ddefeb;
    font-weight: normal;
}

#massages .autour-massage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
}

#massages .autour-massage h2 {
    margin-left: 1rem;
    color: #ddefeb;
}

#autourMassages h3 {
    color: #400101;
    font-weight: 700;
    font-size: 1.4rem;
    text-align: center;

    margin: 1rem auto 1.4rem auto;
}

#autourMassages svg path,
#autourMassages svg line,
#autourMassages svg circle {
    stroke: #400101;
}

#autourMassages ul {
    margin: 1em auto;
    font-size: 1.2rem;
    color: #400101;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;

    text-align: left;
    width: 90%;
}

#tarifs {
    background: #d9843b;
    padding: 3rem 0;
}

#tarifs ul {
    list-style-type: none;
    text-align: center;
    font-size: 1.3rem;
    color: #400101;
}

#tarifs h3.promo-title {
    margin-top: 2rem;
    font-size: 2rem;
}

.icones-paiment {
    align-items: center;
    justify-content: center;
}

.icones-paiment img {
    width: 3rem;
}

.icones-paiment img:nth-child(1) {
    margin-right: 2rem;
}

#tarifs .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#tarifs a {
    color: #400101;
}

#tarifs a:hover {
    color: #400101;
    text-decoration: underline;
}

.tarifs-grid {
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: center;

    margin: 3rem auto 0.5rem auto;
}

.container .tarifs-grid:nth-child(2) {
    margin: 0 auto;
}

@media (min-width: 992px) {
    .tarifs-grid {
        padding: 0 3rem;
    }
}

.tarif-card-body {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    /* border: 2px solid #591902;
    border-radius: 10px 0 10px 0; */
    position: relative;
    -webkit-box-shadow: 2px 2px 3px rgba(64, 1, 1, 0.5);
    box-shadow: 2px 2px 3px rgba(64, 1, 1, 0.5);

    border: 1px solid #591902;

    height: 100%;
    padding: 1rem;

    text-align: center;
}

.tarif-card-body:before,
.tarif-card-body:after {
    display: block;
    content: '';
    width: 30px;
    height: 30px;
    position: absolute;
}

.tarif-card-body:before {
    top: -7px;
    left: -7px;
    border-top: 1px solid #591902;
    border-left: 1px solid #591902;
}

.tarif-card-body:after {
    bottom: -7px;
    right: -7px;
    border-bottom: 1px solid #591902;
    border-right: 1px solid #591902;
}

.tarif-card {
    padding: 1rem 1rem;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    margin-bottom: 2.5rem;
}

@media (min-width: 768px) {
    .tarif-card {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }
}

@media (min-width: 1200px) {
    .tarif-card {
        -ms-flex-preferred-size: 33.3%;
        flex-basis: 35%;
        padding: 0 0.8rem;
    }
}

.tarif-card hr {
    border: 1px solid #a63f03;
    width: 40%;
    margin: 1rem auto;
}

.tarif-card hr.hr-kobido {
    border: 1px solid #a63f03;
    width: 40%;
    margin: 1.2rem auto;
}

.tarif-card img {
    width: 80%;
    margin-top: 0.4rem;
    border: 1px solid #400101;
}

.tarif-card h2,
.tarif-card h3 {
    color: #ddefeb;
    text-shadow: #400101 1px 1px 3px;
}

.tarif-card h2.duree-massage {
    font-size: 1.4rem;
    margin: 0.8rem auto;
}

.tarif-card h2.cure-kobido {
    font-size: 1.4rem;
    margin: 0 auto;
}

.tarif-card h3.prix-massage {
    font-size: 3.2rem;
    height: 3rem;
    font-weight: normal;
    margin: 0 auto;
}

.tarif-card h3.prix-massage-kobido {
    font-size: 2.4rem;
    font-weight: normal;
    margin: 0.4rem auto;
}

.tarif-card p {
    margin: 0 auto;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;

    font-size: 1.2rem;
}

.tarif-card p.prix-barre {
    color: white;
    font-size: 1.5rem;
    text-shadow: #400101 1px 1px 3px;
    position: relative;

    font-family: sans-serif;
}

.tarif-card span.prix-barre {
    position: relative;
}

.tarif-card span.prix-barre::after {
    content: '';
    position: absolute;
    top: 34%;
    left: 0;
    transform: rotateZ(-20deg);
    border-bottom: 2px solid #a63f03c2;
    width: 100%;
}

.tarif-card .forfait {
    margin: 1rem auto;
}

#cadeau-fidelite {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto auto auto;
}

.bon-cadeau,
.carte-fidelite {
    text-align: center;
    padding-top: 3rem;
}

@media (min-width: 992px) {
    #cadeau-fidelite {
        grid-template-columns: 50% 50%;
        grid-template-rows: auto auto auto;
    }

    #cadeau-fidelite h3 {
        grid-row: 1 / 1;
    }

    .illustration-bon,
    .illustration-fidelite {
        grid-row: 2 / 2;
    }

    .description {
        grid-row: 3 / 3;
    }
}

#cadeau-fidelite h3 {
    font-size: 2rem;
    font-weight: 400;
    text-shadow: rgba(64, 1, 1, 0.5) 01px 1px 3px;

    margin: 2rem auto;
    color: #ddefeb;
    text-align: center;
}

.description {
    justify-self: center;
    align-self: center;

    padding: 0 2rem;
}

.illustration-bon,
.illustration-fidelite {
    /* margin: 2rem auto; */
    position: relative;
    width: 80%;

    align-self: center;
    justify-self: center;

    border: 1px solid #400101;
    border-radius: 10px;
    overflow: hidden;
}

@media (min-width: 992px) {
    .illustration-bon,
    .illustration-fidelite {
        width: 70%;
    }
}

.illustration-bon .hide,
.illustration-fidelite .hide {
    opacity: 0;
}

.illustration-bon img,
.illustration-fidelite img {
    width: 100%;
    -webkit-transition: all 0.8s ease;
    -o-transition: all 0.8s ease;
    transition: all 0.8s ease;
    vertical-align: top;
}

.illustration-bon img:last-child,
.illustration-fidelite img:last-child {
    position: absolute;
    top: 0;
    left: 0;
}

.illustration-bon:hover img:first-child,
.illustration-fidelite:hover img:first-child {
    opacity: 0;
}
.illustration-bon:hover img:last-child,
.illustration-fidelite:hover img:last-child {
    opacity: 1;
}

.tickets-commercants {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tickets-flex {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

@media (min-width: 992px) {
    .tickets-flex .tickets-gauche {
        flex-basis: 40%;
    }

    .tickets-flex .tickets-droite {
        flex-basis: 60%;
    }
}

#tarifs .tickets-commercants h3 {
    font-size: 2rem;
    font-weight: 400;
    text-shadow: rgba(64, 1, 1, 0.5) 01px 1px 3px;

    margin: 2rem auto;
    color: #ddefeb;
}

.tickets-droite,
.tickets-gauche {
    padding: 0 1.5rem;
}

.tickets-commercants p {
    margin: 0.5rem 0;
}

.tickets-commercants p a {
    color: #400101;
}
.tickets-commercants p a:hover {
    text-decoration: underline;
}

.tickets-commercants .tickets-gauche img {
    background: white;
    padding: 1rem;
    border-radius: 1rem;
    margin: 2rem 0;
    width: 100%;
}

/* ========== Contact ========== */

#contact {
    padding: 3rem 0;
}

#contact > .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 3rem;
}

.contact-separator {
    width: 20%;
    margin-top: 1.5em;
    border: 0.5px solid #400101;
}

.contact_left p {
    font-size: 1.4rem;
}

.adresse {
    margin: 2rem 0;
}

.contact_left .phone-facebook {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 2rem;
}

.contact_left i {
    margin-bottom: 0.4rem;
}

.contact_left .horaires {
    padding: 0 1.5rem;
}

.contact_left a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
    text-decoration: none;
    color: #400101;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;

    font-size: 1.4rem;
    margin-bottom: 2rem;
    transition: all 0.2s ease;
}

.contact_left a:hover {
    transform: scale(1.1);
}

@media (min-width: 500px) {
    .contact_left .phone-facebook a {
        flex-basis: 50%;
    }
}

.contact_left a:hover {
    color: #400101;
}

.contact_left a[href^='mailto'] {
    font-size: 1.2rem;
    word-break: break-word;
}

.contact_left,
.contact_right {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.contact_left {
    margin-bottom: 4rem;
}

@media (min-width: 992px) {
    .contact_left,
    .contact_right {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
    }

    .contact_left {
        margin: 0;
    }
}

#map {
    min-height: 500px;
    z-index: 1;
}

.leaflet-popup-content,
.leaflet-container:after {
    text-align: center;
}

#avis .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.rate-links {
    display: flex;
}

a.rate-link {
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #400101;

    font-size: 1.4rem;
    margin: 2rem 1rem 0 1rem;
    padding: 0.6rem 1rem;
    transition: all 0.2s ease;

    border: 1px solid #400101;
    border-radius: 5px;

    display: flex;
}

a.rate-link:hover {
    transform: scale(1.05);
}

a.rate-link img {
    width: 2rem;
    margin-right: 0.5rem;
}

/* ========== Information ========= */

#information ul {
    list-style-type: none;
    text-align: center;
    font-size: 1.3rem;
    color: #400101;
}

/* ========== Modal ========= */

.modal-massage {
    display: block;
    padding: 0 1em;
    text-align: center;
    width: 100%;
}

/* Important part */
.modal__overlay {
    overflow-y: initial !important;
    overflow-x: hidden;
}
.modal__box {
    overflow-y: auto;
}

.modal-massage > label:hover {
    -webkit-transform: scale(0.97);
    -ms-transform: scale(0.97);
    transform: scale(0.97);
}

.modal__overlay {
    background: #f0f0f0;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    text-align: center;
    top: 0;
    z-index: -800;
}

.modal__box {
    padding: 1em 0.75em;
    position: relative;
    margin: 1em auto;
    max-width: 700px;
    width: 90%;
}

.modal__box label {
    background: #591902;
    border-radius: 50%;
    color: #f0f0f0;
    cursor: pointer;
    display: inline-block;
    height: 1.5em;
    line-height: 2em;
    position: absolute;
    right: 0.5em;
    top: 0.5em;
    width: 1.5em;
}

.modal__box h2 {
    color: #591902;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.modal__box p,
.modal__box li {
    color: #591902;
    font-size: 1.1rem;
}

.modal__box p {
    text-align: justify;
    margin: 1rem auto;
}

.modal__box p.first-paraph {
    margin-top: 3rem;
}

.modal__box img {
    margin-top: 1rem;
    width: 70%;
}

.modal__overlay {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
}

body.modal__oppened .modal__overlay {
    opacity: 1;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
    z-index: 800;
}

body.modal__oppened {
    overflow-y: hidden;
}

@media (min-width: 992px) {
    .modal__box ul {
        margin-left: 3rem;
    }
}

.modal__box ul li {
    text-align: left;
}

.modal_massage_img img {
    border: 2px solid #a63f03;
    border-radius: 10px;
}

footer {
    background: #f0f0f0;
    padding: 1rem;

    text-align: center;
}

footer p {
    font-size: 1.4rem;
    color: #400101;
    margin: 0;
}

footer p a {
    color: #400101;
}

@media (min-width: 992px) {
    footer p {
        font-size: 1.1rem;
    }
}
